.assistant-inner {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 2px 50px rgb(13 21 38 / 8%);
    border-radius: 40px;
    padding: 19px 10px 35px;
    text-align: center;
    margin: 0 10px;
    min-width: 250px;
    max-width: 250px;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  
  .assistant-img {
    width: 116px;
    height: 116px;
    background: #d9d9d9;
    box-shadow: 0px 3px 34px rgb(234 82 31 / 20%);
    border-radius: 50%;
    position: relative;
    margin: -80px auto 0 auto;
  }
  
  .assistant-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .assistant-inner h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: var(--p-color);
    margin: 15px 0!important;
  }
  
  .assistant-inner p {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(52, 57, 97, 0.8);
    margin-bottom: 15px!important;
    height: 30px;
  }