.profile {
    display: flex;
    align-items: center;
  }
  
  .profile img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  
  .profile h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: var(--p-color);
    margin-left: 8px!important;
  }