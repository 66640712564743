.main-summary {
    padding-top: 61px;
    height: 100vh;
}


.summary-inner {
    width: 100%;
    float: right;
    height: 100%;
    padding: 40px 100px;
    padding-bottom: 100vh;
}

.summary-profile {
    display: flex;
    align-items: center;
}

.summary-profile img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.summary-profile h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #343961;
    margin-left: 8px;
}

.summart-form textarea {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    border: 0;
    outline: 0;
    height: 180px;
    padding: 25px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba(52, 57, 97,0.7);
}

.summart-form textarea::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba(52, 57, 97,0.7);
}

.summary-option {
    position: relative;
    text-align: center;
    margin: 25px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba(52, 57, 97);
}

.summary-option:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 47%;
    top: 50%;
    left: 0;
    background: rgba(52, 57, 97, 0.1);
}

.summary-option:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 47%;
    top: 50%;
    right: 0;
    background: rgba(52, 57, 97, 0.1);
}

.summary-btn {
    text-align: center;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
} 
/* 
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    background: #FFF9F4;
    border: 1px dashed #EF7B1B;
    border-radius: 20px;
}
  
.upload-btn-wrapper .btn {
    width: 100%;
    height: 90px;
    border: 0;
    outline: 0;
    background: transparent;
}

.upload-btn-wrapper input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
}

.upload-btn-wrapper .btn h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #343961;
}

.upload-btn-wrapper .btn h6 span {
    color: rgba(234, 80, 31, 1);
}

.upload-btn-wrapper .btn p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgb(52 57 97 / 50%);
    margin-top: 10px;
} */

.summary-inner.summary-inner-one {
    width: 100%;
    padding: 30px 10px;
    float: none;
}

@media (max-width:991px) {
    .summary-inner {
        width: 100%;
        float: none;
        padding: 40px 10px;
    }
    
}

/* style for response page */

.search-label {
    margin-top: 20px;
}

.search-label label {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #343961;
    margin-bottom: 11px;
    display: inline-block;
}

.search-label textarea {
    margin: 0 !important;
}

.search-label-1 textarea {
    height: 250px;
}

.summart-form input {
    width: 100%;
    background: #FFFFFF;
    border-radius: 15px;
    border: 0;
    outline: 0;
    height: 50px;
    padding: 25px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba(52, 57, 97);
    margin: 0;
}

.summart-form input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba(52, 57, 97, 0.7);
}

.skip-search-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.skip-search-button button:first-child {
    margin-right: 10px;
}

.summary-btn {
    text-align: center;
    cursor: pointer;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.summary-btn button:first-child {
    margin-right: 10px;
}
.summary-inner.summary-inner-one {
    width: 100%;
    padding: 30px 10px;
    float: none;
}

@media (max-width:991px) {
    .summary-inner {
        width: 100%;
        float: none;
        padding: 40px 10px;
    }

}