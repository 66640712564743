.heading {
    position: relative;
}

.heading h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 41px;
    text-align: center;
    color: var(--p-color);
}

@media (max-width:1024px) {
    .heading h1 {
        font-size: 28px;
        line-height: 32px;
    }
}