.search {
  position: relative;
  height: 55px;
}

.send-btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom:0;
}

.search input {
  width: calc(100% - 100px);
  background: #ffffff;
  border: 0;
  outline: 0;
  height: 100%;
  border-radius: 20px 0 0 20px;
  padding-left: 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--p-color);
  float: left;
}

.search input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--p-color);
}

.search .send-btn {
  background: linear-gradient(91.27deg, #ea521f 9.34%, #ef7b1b 86.8%);
  outline: none;
  border: none;
  height: 100%;
  border-radius: 0 20px 20px 0;
  padding: 20px;
}

.search .send-btn {
  height: 100%;
}

.search .send-btn img,
.search .send-btn svg {
  position: relative;
}

.text-height {
  height: 55px;
}