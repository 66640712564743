.modal-header {
    border: none !important;
}

.modal-wrapper {
    background: var(--body-bg);
}

.heading {
    color: #EA501F;
    font-size: 26px;
    line-height: 12px;
    font-weight: 500;
    padding: 10px 0;
}