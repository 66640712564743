.check-login {
    position: relative;
    padding: 10px;
  }
  
  .check-login:first-child {
    padding-left: 0;
  }
  
  .check-login:last-child {
    padding-right: 0;
  }
  
  .check-login input {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    top: 0;
    left: 0;
  }
  
  .check-login label {
    background: rgba(255, 255, 255, 0.4);
    /* 0.4 */
    box-shadow: 0px 2px 50px rgba(13, 21, 38, 0.08);
    border-radius: 40px;
    display: block;
    text-align: center;
    padding: 20px;
    width: 300px;
  }
  
  .check-login label img,
  .check-login label svg {
    height: 134px;
    width: 134px;
    object-fit: cover;
  }
  
  .check-login label h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: var(--p-color);
    margin: 15px 0 15px 0 !important;
  }
  
  .check-login label p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--p-color);
    color: rgb(52, 57, 97, 0.8);
    /* 0.8 */
  }
  
  @media (max-width: 768px) {
    .check-login label h6 {
      margin: 0;
    }
  
    .check-login label {
      width: 100%;
    }
  
    .check-login label img,
    .check-login label svg {
      width: 90px;
      height: 90px;
      margin-bottom: 10px;
    }
  }