.previousbtn {
    padding: 0 100px;
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 1;
}

.previousbtn button {
    border: 0;
    background: transparent;
    color: var(--text-color);
    font-size: 16px;
    line-height: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.previousbtn button img,
.previousbtn button svg {
    padding-right: 5px;
}