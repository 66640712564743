.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    background: #fff9f4;
    border: 1px dashed #ef7b1b;
    border-radius: 20px;
  }
  
  .upload-btn-wrapper .btn {
    width: 100%;
    height: 90px;
    border: 0;
    outline: 0;
    background: transparent;
  }
  
  .upload-btn-wrapper input[type="file"] {
    position: absolute;
    left: -120px;
    top: 0;
    opacity: 0;
    height: 100%;
    width: calc(100% + 120px);
    cursor: pointer;
  }
  
  .upload-btn-wrapper .btn h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #343961;
  }
  
  .upload-btn-wrapper .btn h6 span {
    color: var(--text-color);
  }
  
  .upload-btn-wrapper .btn p {
    font-size: 12px;
    color: rgb(52 57 97 / 50%);
    margin-top: 8px !important;
  }