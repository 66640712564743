.tag-main {
    height: 100vh;
    margin-top: 90px;
}


.form-user {
    /* height: 100%;
    max-width: 800px;
    margin: 0 auto; */
    width: 90%;
}

.form-user form {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.form-user-head h3 {
    color: #EA501F;
    font-size: 26px;
    line-height: 12px;
    font-weight: 500;
    padding: 10px 0;
}

.form-user input {
    width: 100%;
    background: #FFFFFF;
    border: 0;
    outline: 0;
    max-width: 730px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgb(49 54 78 / 100%);
    /* padding: 10px; */
}

.form-user input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgb(49 54 78 / 100%);
}

.search-back {
    justify-content: space-around;
    align-items: center;
}

.search-addProduct {
    justify-content: space-between;
    align-items: center;
}