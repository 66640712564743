header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 50px rgba(13, 21, 38, 0.08);
    padding: 15px 0;
}

.header-img svg {
    cursor: pointer;
}