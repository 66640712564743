.backbutton-wrapper {
    margin: 20px 0 15px;
}

.backbutton-wrapper .previousbtn {
    position: static;
    padding: 0;
}

.backbutton-wrapper .previousbtn button {
    display: inline-flex;
    padding: 12px 0;
    line-height: normal;
}

.file-selector {
    margin-bottom: 30px;
    margin-top: 16px;
}

/* Empty state */
.empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    min-height: 300px;
    color: rgba(52, 57, 97, 0.5);
}

.empty-state img {
    margin-bottom: 15px;
}


/* Custom Radio Button*/
.custom-radio-selector-wrapper {
    max-height: calc(100vh - 400px);
    overflow: auto;
    padding-right: 16px;
}

.custom-radio-selector {
    position: relative;
    z-index: 1;

    cursor: pointer;
    /* margin-bottom: 12px; */
}

.custom-radio-selector label {
    padding: 16px 16px;
}

.custom-radio-selector-wrapper .custom-radio-selector:not(:last-child):after {
    position: absolute;
    content: "";
    bottom: -2px;
    left: 12px;
    right: 12px;
    height: 1px;
    background: rgba(52, 57, 97, 0.1);
    z-index: -1;
}

.custom-radio-selector input[type="radio"] {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
}

.custom-radio-selector label {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #343961;
    cursor: pointer;
}

.custom-radio-selector label span:not(.radio-icon):not(.radio-selected) {
    padding-top: 4px;
}

.custom-radio-selector .radio-icon {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 89.58%);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: block;
    width: 14px;
    height: 14px;
    position: relative;
    margin-right: 12px;
    /* margin-bottom: 4px; */
    cursor: pointer;
}

.custom-radio-selector .radio-icon:after {
    position: absolute;
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: linear-gradient(91.27deg, #EA521F 9.34%, #EF7B1B 86.8%);
    border-radius: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    opacity: 0;
    pointer-events: none;
}

.custom-radio-selector .radio-icon:before {
    position: absolute;
    content: "";
    inset: 0;
    border-radius: 50%;
    padding: 1px;
    background: linear-gradient(180deg, #D7D7D7, #EFDFDF);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

.custom-radio-selector input[type="radio"]:checked~.radio-icon:before {
    background: linear-gradient(91.27deg, #EA521F 9.34%, #EF7B1B 86.8%),
        linear-gradient(95.81deg, #C10016 15.17%, #FF344B 89.37%);
}

.custom-radio-selector input[type="radio"]:checked~.radio-icon:after {
    opacity: 1;
}

.custom-radio-selector .radio-selected {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    background: rgba(255, 249, 244, 0.9);
    border: 1px solid rgba(239, 123, 27, 0.6);
    border-radius: 10px;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
}

.custom-radio-selector input[type="radio"]:checked~.radio-selected {
    opacity: 1;
}

.custom-radio-selector-wrapper .custom-radio-selector.selected:after {
    display: none;
}

.chat-main {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}


.secret-key-submit-btn {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.secret-key-submit-btn button:first-child {
    margin-right: 10px;
}

.message {
    height: calc(100% - 200px);
    overflow-y: auto;
    padding: 0 10px;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.message-inner-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    min-height: 100%;
}


.message-img {
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 15px;
    border-radius: 50%;
    flex: 0 0 25px;
}

.message-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}


.message .time {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #343961;
    opacity: 0.5;
}

.time-left {
    text-align: left;
}

.time-right {
    text-align: right;
}

.message-inner-right {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.message-inner-left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.message-right {
    position: relative;
    background: linear-gradient(91.27deg, #EA521F 9.34%, #EF7B1B 86.8%);
    ;
    padding: 8px 10px;
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: start;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: left;
    margin: 0 0 0 auto;
    max-width: fit-content;
}

.message-left {
    position: relative;
    padding: 8px 10px;
    border-radius: 0 10px 10px 0;
    background: #FFFFFF;
    border: 0.5px solid rgb(239 123 27 / 60%);
    display: flex;
    align-items: start;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #343961;
    text-align: left;
    margin: 0 auto 0 0;
    max-width: fit-content;
}

.search {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    height: 55px;
}

.chat-ask {
    position: fixed;
    bottom: 30px;
    width: 100%;
}