.commanbtn {
    display: inline-block;
    background: var(--link-color);
    mix-blend-mode: normal;
    border-radius: 24px;
    max-width: 250px;
    padding: 12px 0;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    border: 0;
}

.commanbtn1 {
    background: var(--body-bg-btn);   
}

