textarea {
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  border: 0;
  outline: 0;
  /* height: 180px; */
  padding: 4px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(52, 57, 97, 0.7);
  overflow: auto;
}

textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(52, 57, 97, 0.7);
}

.textarea-custom {
  height: 100%;
  width: calc(100% - 65px);
  resize: none;
  /* line-height: 50px; */
  padding: 0 20px;
  border-radius: 20px 0 0px 20px;
}

.textarea-custom::placeholder {
  line-height: 50px;
}

.error {
  font-size: 12px;
  color: red;
  padding: 0
}