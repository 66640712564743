* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Epilogue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--body-bg);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
}

.wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 57px;
}

.wrapper-inner {
  position: relative;
  padding-top: 57px;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.container {
  height: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}


.main {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0;
}



/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(91.27deg, #ea521f 9.34%, #ef7b1b 86.8%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg,
      #fff5e0 0%,
      #d7bbc7 50.52%,
      #bcb5d7 76.56%,
      #98afc6 100%);
}

.disable-tag {
  pointer-events: none;
  opacity: 0.3;
}

.text-orange {
  color: #FF9900;
}