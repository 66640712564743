.chat-main {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.chat-ask {
    position: fixed;
    bottom: 30px;
    width: 100%;
}

.chat-img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    background: #D9D9D9;
    box-shadow: 0px 2.14px 24.2533px rgba(234, 82, 31, 0.2);
    margin-bottom: 30px;
}

.chat-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.chat-inner h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #343961;
}


.search {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    height: 55px;
}

.send-btn {
    position: absolute;
    right: 0;
}

.search input {
    width: 100%;
    background: #FFFFFF;
    border: 0;
    outline: 0;
    height: 100%;
    max-width: 730px;
    border-radius: 20px 0 0 20px;
    padding-left: 25px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgb(49 54 78 / 100%);
    float: left;
}

.search input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgb(49 54 78 / 100%);
}

.search .send-btn button {
    background: linear-gradient(91.27deg, #EA521F 9.34%, #EF7B1B 86.8%);
    outline: none;
    border: none;
    height: 100%;
    border-radius: 0 20px 20px 0;
    padding: 20px;
}

.search .send-btn {
    height: 100%;
}

.search .send-btn img {
    position: relative;
    top: -5px;
}

/* chat */
.message {
    height: calc(100% - 220px);
    overflow-y: auto;
    padding: 0 10px;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.message p {
    margin: 0 !important;
}

.message-img {
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 15px;
    border-radius: 50%;
    flex: 0 0 25px;
}

.message-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}


.message .time {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #343961;
    opacity: 0.5;
}

.time-left {
    text-align: left;
}

.time-right {
    text-align: right;
}


.message-inner-right {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.message-inner-left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.message-right {
    position: relative;
    background: linear-gradient(91.27deg, #EA521F 9.34%, #EF7B1B 86.8%);
    ;
    padding: 8px 10px;
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: start;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: left;
    margin: 0 0 0 auto;
    max-width: fit-content;
}

.message-left {
    position: relative;
    padding: 8px 10px;
    border-radius: 0 10px 10px 0;
    background: #FFFFFF;
    border: 0.5px solid rgb(239 123 27 / 60%);
    display: flex;
    align-items: start;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #343961;
    text-align: left;
    margin: 0 auto 0 0;
    max-width: fit-content;
}

.message-inner-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    min-height: 100%;
}

.no-chat {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: linear-gradient(91.27deg, #EA521F 9.34%, #EF7B1B 86.8%);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #FFF5E0 0%, #D7BBC7 50.52%, #BCB5D7 76.56%, #98AFC6 100%);
}


@media (max-width:912px) {
    .chat-main {
        justify-content: flex-start;
        margin-top: 33px;
    }
}

@media (max-width:767px) {
    .chat-ask {
        padding: 0 10px;
    }

    .search input {
        border-radius: 20px !important;
    }
}