.inputwarpper label {
  /* float: left; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #343961;
}
input {
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  border: 0;
  outline: 0;
  padding: 10px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(52, 57, 97);
  margin: 0;
}

input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(52, 57, 97, 0.7);
}
.formInput {
  display: flex;
  flex-direction: column;
  min-height: 54px;
}
.error {
  font-size: 12px;
  color: red;
  padding: 0
}
