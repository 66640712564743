.help {
    text-align: center;
    position: relative;
}

.help-img {
    width: 112px;
    height: 112px;
    margin: 0 auto;
    border-radius: 50%;
}

.help-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.help-content p {
    font-size: 20px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 25px;
    color: var(--p-color);
}