.table-wrapper {
    width: 100%;
    overflow-x: auto;
    position: relative;
    overflow-y: auto;
    height: calc(100vh - 340px);

}

.table-pagination-dropdown {
    min-width: unset;
}

.cell-padding-10 td {

    padding: 10px 10px 10px 24px !important;

}

.cell-padding-8 td {

    padding: 8px 8px 8px 24px !important;

}

.cell-padding-5 td {

    padding: 5px 5px 5px 24px !important;

}

.pagination-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #667085;
}

table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 -1px 2px rgb(16 24 40 / 10%), 0 -1px 2px rgb(16 24 40 / 6%);
    border-radius: 8px 8px 0 0;
    border-collapse: collapse;



}


tbody tr {
    cursor: pointer;
}


td {
    cursor: pointer;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
    font-weight: 500;
    color: #101828;
}

.table-modal {
    width: 80px;
    height: 70px;
    display: none;
}

.open {
    width: 80px;
    height: 70px;
    display: block;
}

.no-data-found {
    position: absolute;
    left: 0;
    right: 0;
    padding: 8px 0;
    transform: translate(0, 50%);
    margin-top: -15px;
}