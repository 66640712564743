.head {
    padding-top: 40px;
}

.login-boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 100px;
}


@media (max-width:767px) {
    .login-boxes {
        padding-top: 50px;
    }
}