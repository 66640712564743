.assistant-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 65px; */
    flex-wrap: wrap;
}

.assistant-inner {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 2px 50px rgb(13 21 38 / 8%);
    border-radius: 40px;
    padding: 19px 10px;
    text-align: center;
    margin: 0 10px;
    min-width: 300px;
    max-width: 300px;
    margin-top: 40px;
    margin-bottom: 30px;
}

.assistant-img {
    width: 116px;
    height: 116px;
    background: #D9D9D9;
    box-shadow: 0px 3px 34px rgb(234 82 31 / 20%);
    border-radius: 50%;
    position: relative;
    margin: -80px auto 0 auto;
}

.assistant-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.assistant-inner h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #343961;
    margin: 15px 0;
}

.assistant-inner p {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(52, 57, 97, 0.8);
    margin-bottom: 15px;
    height: 40px;
}

.dcs-inner {
    position: relative;
    max-width: 800px;
    padding: 40px 0;
    margin: 0 auto;
}

.search Input {
    width: 100%;
}

.summary-btn {
    bottom: 0;
    position: fixed;
    right: 0 !important;
    text-align: center;
    cursor: pointer;

    margin-top: 30px;
    display: flex;
    justify-content: flex-end !important;
    background: #ECF0F5;
    box-shadow: 0px 2px 50px rgba(13, 21, 38, 0.08);
    padding: 15px 5px;
    z-index: 9;
    width: 100%;
}

.summary-btn button:first-child {
    margin-right: 10px;
}

.dcs-inner .summary-profile {
    padding-bottom: 20px;
}

.select-form {
    padding-top: 20px;
    max-width: 800px;
}

.select-form .react-select__control {
    width: 100%;
    background: #FFFFFF;
    border-radius: 15px;
    border: 0;
    outline: 0;
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba(52, 57, 97);
    margin: 0;
}



.select-form .react-select__indicator svg {
    fill: #000;
}


.search.document-search {
    width: 100%;
    display: flex;
}

.document-search-custom {
    display: block !important;
    max-width: 800px !important;
}

.search.document-search input {
    width: 100%;
    max-width: 100%;
    border-radius: 20px;
}

@media (max-width:1024px) {
    .assistant-img {
        width: 100px;
        height: 100px;
    }
}

@media (max-width:912px) {
    .assistant-outer {
        display: block;
        margin-top: 0;
    }

    .assistant-inner {
        margin-top: 100px;
        margin-bottom: 30px;
    }

}

.profileCard {
    cursor: pointer;
}


.chat-main {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.chat-ask {
    position: fixed;
    bottom: 30px;
    width: 100%;
}

.chat-img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    background: #D9D9D9;
    box-shadow: 0px 2.14px 24.2533px rgba(234, 82, 31, 0.2);
    margin-bottom: 30px;
}

.chat-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.chat-inner h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #343961;
}

.custom-search{
    /* added style for search i/p */
    margin-left: 0px !important;
}
.doc-response .inputwarpper{
    max-width: 800px;
}
.generated-response{
    max-width: 800px;
}

.search {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    height: 55px;
}

.send-btn {
    position: absolute;
    right: 0;
}

.search input {
    width: 100%;
    background: #FFFFFF;
    border: 0;
    outline: 0;
    height: 100%;
    max-width: 730px;
    border-radius: 20px 0 0 20px;
    padding-left: 25px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgb(49 54 78 / 100%);
    float: left;
}

.search input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgb(49 54 78 / 100%);
}

.search .send-btn button {
    background: linear-gradient(91.27deg, #EA521F 9.34%, #EF7B1B 86.8%);
    outline: none;
    border: none;
    height: 100%;
    border-radius: 0 20px 20px 0;
    padding: 20px;
}

.search .send-btn {
    height: 100%;
}

.search .send-btn img {
    position: relative;
    top: -5px;
}

/* chat */
.message {
    height: calc(100% - 220px);
    overflow-y: auto;
    padding: 0 10px;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.message p {
    margin: 0 !important;
}

.message-img {
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 15px;
    border-radius: 50%;
    flex: 0 0 25px;
}

.message-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}


.message .time {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #343961;
    opacity: 0.5;
}

.time-left {
    text-align: left;
}

.time-right {
    text-align: right;
}


.message-inner-right {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.message-inner-left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.message-right {
    position: relative;
    background: linear-gradient(91.27deg, #EA521F 9.34%, #EF7B1B 86.8%);
    ;
    padding: 8px 10px;
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: start;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: left;
    margin: 0 0 0 auto;
    max-width: fit-content;
}

.message-left {
    position: relative;
    padding: 8px 10px;
    border-radius: 0 10px 10px 0;
    background: #FFFFFF;
    border: 0.5px solid rgb(239 123 27 / 60%);
    display: flex;
    align-items: start;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #343961;
    text-align: left;
    margin: 0 auto 0 0;
    max-width: fit-content;
}

.message-inner-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    min-height: 100%;
}

.submit-btn {
    width: 130px;
}

.back-btn {
    width: 130px;

}

.dropdown .react-select__indicator-separator {
    margin-top: 0 !important;
}

.dropdown .react-select__value-container .react-select__value-container--has-value {
    padding: 0px 8px !important;
}

.dropdown .react-select__control {
    height: 50px !important;
}

.dropdown .react-select__input-container {
    padding-top: 0 !important
}

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: linear-gradient(91.27deg, #EA521F 9.34%, #EF7B1B 86.8%);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #FFF5E0 0%, #D7BBC7 50.52%, #BCB5D7 76.56%, #98AFC6 100%);
}


@media (max-width:912px) {
    .chat-main {
        justify-content: flex-start;
        margin-top: 33px;
    }
}

@media (max-width:767px) {
    .chat-ask {
        padding: 0 10px;
    }

    .search input {
        border-radius: 20px !important;
    }
}

.info-on-select {
    border: 1px dashed;
    position: fixed;
    top: 50%;
    right: 50px;
    width: 30%;
    transform: translateY(-50%);
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    max-height: 80vh;
    overflow-y: scroll;
}

.tag {
    display: inline-block;
    padding: 5px 10px;
    background-color: #eee;
    border: 1px solid;
    border-radius: 100px;
    margin: 3px;
}

.heading-tile {
    padding: 10px 0;
}

.generated-summary-container > p {
    max-width: 700px;
}